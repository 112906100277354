<template>
  <v-row>
    <v-dialog v-model="dialogScanner" width="550px">
      <v-card>
        <v-card-title class="primary white--text">Adicionar Gaiola
          <v-spacer/>
          <div class="caption font-weight-bold">Empresa: {{pedido.numeroPedido}}</div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-container>
            <!-- Lista de campos adicionais -->
            <v-row v-for="(item, index) in itens" :key="index" @mouseenter="itens[index].status2 = true" @mouseleave="itens[index].status2 = false">
              <v-col class="pa-0">
                <v-autocomplete
                    :label="'Código Gaiola ' + (index+1)"
                    :items="opcoes"
                    hide-details
                    autofocus
                    dense
                    class="mt-6"
                    @change="validarGaiola(index)"
                    outlined
                    :disabled="itens[index].status"
                    v-model="itens[index].codigo"
                    auto-select-first
                    clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="auto" class="pa-0 d-none d-md-block">

                <v-btn
                    v-if="itens[index].status2 && itens[index].status"
                    dark
                    @click="excluirGaiola(index)"
                    class="mt-6 ml-3 error dark"
                    height="38px"
                >
                  Excluir
                </v-btn>

              </v-col>
              <v-col cols="auto" class="pa-0 d-inline-flex d-md-none">
                <v-btn
                    v-if="itens[index].status"
                    dark
                    @click="excluirGaiola(index)"
                    class="mt-6 pa-0 ml-3 error dark"
                    icon
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-alert dismissible type="error" elevation="0" icon="mdi-alert" class="ma-0 mt-3" v-model="alerta.status">{{alerta.msg}}</v-alert>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="px-6 py-3">
          <v-btn class="primary" width="100%" elevation="" @click="dialogScannerConfirmacao=true, dialogScanner=false">Gravar quantidade</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogScannerConfirmacao" width="550px">
      <v-card>
        <v-card-title class="primary white--text">Confirmação de quantidade
          <v-spacer/>
          <div class="caption font-weight-bold">Pedido: {{pedido.numeroPedido}}</div>
        </v-card-title>
        <v-card-text class="mt-6">
          <div class="text-h4 text-center font-weight-bold">Total de gaiolas conferidas</div><br/>
          <div class="text-center fonte-numero pt-3 pb-6">{{itens.length-1}}</div>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="py-3 d-flex justify-space-between">
          <v-btn class="error flex-grow-1" elevation="" @click="dialogScannerConfirmacao=false, dialogScanner=true">Reconferir</v-btn>
          <v-btn class="primary flex-grow-1 ml-3" elevation="" @click="dialogScannerConfirmacao=true">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-tabs v-model="tab" background-color="primary" grow show-arrows dark>
        <v-tab :disabled="carregando" class="white--text">Por Filial</v-tab>
        <v-tab :disabled="carregando" class="white--text">Por Gaiolas</v-tab>
        <v-tab :disabled="carregando" class="white--text">Por Paletes</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat dark color="primary" class="rounded-0">
            <v-card-text>
              <v-skeleton-loader light v-if="carregando" type="table-tbody" />
              <v-data-table
                  v-else-if="pedidos"
                  dense
                  light
                  :headers="[
                          { text: 'Código', value: 'codFilial', sortable: true, align:'center'},
                          { text: 'Filial', value: 'filial', sortable: true, align:'center'},
                          { text: 'Ultima Alteração', value: 'ultimaAlt', sortable: true, align:'center'},
                          { text: 'Qtd. Gaiolas', value: 'qtdGaiolas', sortable: true , align:'center'},
                          { text: 'Qtd. Paletes', value: 'qtdPaletes', sortable: true , align:'center'},
                      ]"
                  :items="[
                          { codFilial: 1, filial: 'Matriz', qtdGaiolas: 13, qtdPaletes: 14 },
                          { codFilial: 2, filial: 'Filial A', qtdGaiolas: 10, qtdPaletes: 12 },
                          { codFilial: 3, filial: 'Filial B', qtdGaiolas: 8, qtdPaletes: 9 },
                          { codFilial: 4, filial: 'Filial C', qtdGaiolas: 5, qtdPaletes: 7 },
                          { codFilial: 5, filial: 'Filial D', qtdGaiolas: 18, qtdPaletes: 20 }
                      ]"
                  item-key="name"
                  class="elevation-1"
                  hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-center pl-0"> {{ item.codFilial }} </td>
                    <td class="text-center pl-0"> {{ item.filial }} </td>
                    <td class="text-center pl-0 d-none d-sm-block"> 28/07/2023 09:52 </td>
                    <td class="pl-0 text-center">
                      <v-btn @click="dialogScanner = true" color="blue-grey" x-small class="white--text" elevation="0">
                        <div style="width: 29px" class="pr-2 text--white">
                          {{ item.qtdGaiolas }}
                        </div>
                        <v-divider color="white" vertical/>
                        <v-icon small class="pl-3">mdi-plus</v-icon>
                      </v-btn>
                    </td>
                    <td class="pl-0 text-center">
                      <v-btn @click="dialogScanner = true" color="brown darken-1" x-small class="white--text" elevation="0">
                        <div style="width: 29px" class="pr-2">
                          {{ item.qtdPaletes }}
                        </div>
                        <v-divider color="white" vertical/>
                        <v-icon small class="pl-3">mdi-plus</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat dark color="primary" class="rounded-0">
            <v-card-text>
              <v-skeleton-loader light v-if="carregando" type="table-tbody" />
              <v-simple-table v-else-if="pedidos" fixed-header dense height="50vh" light>
                <thead>
                <tr class="grey lighten-1">
                  <th class="text-center">Filial</th>
                  <th class="text-center">Nº Pedido</th>
                  <th class="text-center">Usuário</th>
                  <th class="text-center">Data Pedido</th>
                  <th class="text-center">Qtd. Gaiolas</th>
                  <th class="text-center">Qtd. Paletes</th>
                  <th class="text-center"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(t, i) in pedidos" :key="i">
                  <td class="text-center">{{t.idEmpresa }}</td>
                  <td class="text-center">{{t.numeroPedido }}</td>
                  <td class="text-center">{{t.usuario}}</td>
                  <td class="text-center">{{t.dataMovimento}}</td>
                  <td class="text-center"><v-chip small class="text-caption" :color="t.status == 'Concluído' ? 'green darken-4' : 'amber darken-4'" dark>{{t.status}}</v-chip></td>
                  <td class="text-center"><v-btn class="primary" small @click="dialogScanner = true, pedido = t"><v-icon>mdi-barcode-scan</v-icon></v-btn></td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat dark color="primary" class="rounded-0">
            <v-card-text>
              <v-skeleton-loader light v-if="carregando" type="table-tbody" />
              <v-simple-table v-else-if="pedidos" fixed-header dense height="50vh" light>
                <thead>
                <tr class="grey lighten-1">
                  <th class="text-center">Filial</th>
                  <th class="text-center">Nº Pedido</th>
                  <th class="text-center">Usuário</th>
                  <th class="text-center">Data Pedido</th>
                  <th class="text-center">Qtd. Gaiolas</th>
                  <th class="text-center">Qtd. Paletes</th>
                  <th class="text-center"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(t, i) in pedidos" :key="i">
                  <td class="text-center">{{t.idEmpresa }}</td>
                  <td class="text-center">{{t.numeroPedido }}</td>
                  <td class="text-center">{{t.usuario}}</td>
                  <td class="text-center">{{t.dataMovimento}}</td>
                  <td class="text-center"><v-chip small class="text-caption" :color="t.status == 'Concluído' ? 'green darken-4' : 'amber darken-4'" dark>{{t.status}}</v-chip></td>
                  <td class="text-center"><v-btn class="primary" small @click="dialogScanner = true, pedido = t"><v-icon>mdi-barcode-scan</v-icon></v-btn></td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
// import InputDatePicker from "../Widgets/InputDatePicker";
import axios from "axios";

export default {
  name: "ControleGaiola",
  mixins: [mixinFilial],
  // components: {
  //     InputDatePicker,
  // },
  data: () => ({
    tab: 0,
    alerta: { status: false , msg: ''},
    dialogScanner: false,
    dialogScannerConfirmacao: false,
    sheet: false,
    carregando: false,
    busca: {
      dtinicio: `${new Date().getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-01`,
      dtfim: `${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getDate()}`,
      idfilial: null,
      status: 1,
    },
    lista: [
      { codigosBarras: "123456", Nome: "gaiola 01" },
      { codigosBarras: "654321", Nome: "gaiola 02" },
      { codigosBarras: "987654", Nome: "gaiola 03" },
    ],
    pedidos : [
      { idEmpresa: 1, numeroPedido: "PED-001", dataMovimento: "2023-07-24", status: 'Pendente', usuario: "123-João"},
      { idEmpresa: 2, numeroPedido: "PED-002", dataMovimento: "2023-07-25", status: 'Concluído', usuario: "123-Maria"},
      { idEmpresa: 1, numeroPedido: "PED-003", dataMovimento: "2023-07-26", status: 'Pendente', usuario: "123-Carlos"},
      { idEmpresa: 3, numeroPedido: "PED-004", dataMovimento: "2023-07-27", status: 'Pendente', usuario: "123-Ana"},
      { idEmpresa: 2, numeroPedido: "PED-005", dataMovimento: "2023-07-28", status: 'Pendente', usuario: "123-Lucas"},
      { idEmpresa: 1, numeroPedido: "PED-006", dataMovimento: "2023-07-29", status: 'Pendente', usuario: "123-Isabela"},
      { idEmpresa: 3, numeroPedido: "PED-007", dataMovimento: "2023-07-30", status: 'Concluído', usuario: "123-Mariana"},
      { idEmpresa: 2, numeroPedido: "PED-008", dataMovimento: "2023-07-31", status: 'Concluído', usuario: "123-Rafael"},
      { idEmpresa: 1, numeroPedido: "PED-009", dataMovimento: "2023-08-01", status: 'Pendente', usuario: "123-Eduardo"},
      { idEmpresa: 3, numeroPedido: "PED-010", dataMovimento: "2023-08-02", status: 'Concluído', usuario: "123-Camila"},
    ],
    pedido: {},
    status: [
      { id: 1,condicao: 'Pendente', cor: 'amber darken-4'},
      { id: 2,condicao: 'Concluído', cor: 'green darken-4'},
    ],
    itens: [{codigo: '', status: false, status2: false}], // Lista para armazenar os itens adicionais
    opcoes: ['000001','000002','000003','000004','000005'],
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.map((v) => {
          const filial = {};
          filial.idfilial = v.idfilial;
          filial.filial = `${v.idfilial} - ${v.filial}`;
          return filial;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    get() {

    },
    validarGaiola(index){
      this.carregando = true;
      console.log(this.itens[index].codigo.toString())
      console.log(this.itens[index].codigo.toString().length)
      if (this.itens[index].codigo.toString().length==6) {
        this.adicionarGaiola(index)
        this.carregando = false;
        this.alerta.status = false;
        this.alerta.msg = ''
      } else {
        this.alerta.status = true;
        this.alerta.msg = 'O código deve conter 6 dígitos'
        return axios
            .post(`${this.backendUrl}controle/gaiola/validar`, {
              codgioGaiola: this.itens[index].codigo,
            })
            .then((res) => {
              this.dashboard = res.data;
              this.carregando = false;
            })
            .catch(() => {
              this.carregando = false;
            });
      }
    },
    excluirGaiola(index) {
      if (index >= 0 && index < this.itens.length) {
        this.itens.splice(index, 1);
      } else {
        console.error("Índice inválido!");
      }
    },
    adicionarGaiola(index) {
      this.itens[index].status = true
      this.itens.push({codigo: '', status: false, status2: false}); // Adiciona um novo item vazio à lista de itens
    },
    async init() {
      this.busca.idfilial = this.usuario.idfilial;
      await this.get();
    },
  },
  watch: {
    "busca.dtfim": function () {
      this.get()
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtinicio = this.busca.dtfim;
      }
    },
    "busca.dtinicio": function () {
      this.get()
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtfim = this.busca.dtinicio;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.fonte-numero {
  font-family: "Arial Black", sans-serif;
  font-size: 4em;
}

</style>